import React from "react"

import Layout from "../components/allstar/layout"
import SectionHero from "../components/allstar/resource/press-release-template/section-hero"
import SectionBody from "../components/allstar/resource/press-release-template/section-body"

const PressReleaseTemplatePage = props => {
  return (
    <Layout
      location={props.location}
      title={"Press Release Template"}
      isResourceSite
    >
      <SectionHero />
      <SectionBody />
    </Layout>
  )
}

export default PressReleaseTemplatePage
