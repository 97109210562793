import React from "react"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  Flex,
  Heading,
  Text,
  Link,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react"

import {
  ALL_STAR_MAIL_LINK,
  ALL_STAR_PAGES_URL,
  AGH_NETWORK,
} from "../../../../constants/constants"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
    bg: "#F4F7FF",
    justifyContent: "center",
  },
  content: {
    my: { base: "24px", md: "24px", lg: "60px" },
    width: "1080px",
  },
  "inner-box": {
    p: { base: "20px", md: "36px", lg: "36px" },
    maxWidth: "764px",
    bg: "#FFFFFF",
    boxShadow: "0px 8px 12px 0px #2D37480D",
    borderRadius: "8px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  title: {
    fontSize: { base: "18px", md: "18px", lg: "24px" },
    fontWeight: 700,
    lineHeight: { base: "21.48px", md: "21.48px", lg: "32px" },
  },
  note: {
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: "bold",
    lineHeight: "24px",
    color: "#6E7E99",
  },
  text: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2D3748",
  },
  link: {
    fontSize: "16px",
    color: "#3399FF",
    fontWeight: 400,
    lineHeight: "24px",
  },
}

const SectionBody = () => {
  return (
    <Flex sx={styles.root}>
      <Box sx={styles.content}>
        <Box sx={styles["inner-box"]}>
          <Heading sx={styles.heading}>Press Release Template</Heading>
          <Text sx={{ fontWeight: "bold", mt: "10px" }}>[DATE]</Text>
          <Heading sx={styles.title} color="#129459" pt="20px">
            [Hospital] Honored as [Specialty] Healthcare Research All-Star
          </Heading>

          <Text sx={styles.note} mt="20px">
            Award from Avant-garde Health goes to top 5% of hospitals, surgeons
            publishing leading-edge research
          </Text>

          <Text sx={styles.text} mt="20px">
            <strong>[City, State, Date]</strong> - Today{" "}
            <strong>[Hospital Name]</strong> announced its inclusion as a{" "}
            <strong>[Specialty]</strong> Research All-Star for 2024.{" "}
          </Text>

          <Text sx={styles.text} mt="20px">
            [Hospital executive quote here]
          </Text>

          <Text sx={styles.text} mt="20px">
            This honor recognizes the top 5% of hospitals and surgeons in the
            United States publishing leading-edge research in{" "}
            <strong>[Specialty]</strong> care, which ultimately leads to better
            patient outcomes. More than 90,000 physicians and 4,000 hospitals
            were considered for the awards, sponsored by Avant-garde Health, the
            leading provider of solutions for improving perioperative quality
            and profitability.
          </Text>
          <Box mt="20px">
            <Text sx={styles.text}>
              The All-Star surgeons honored at <strong>[Hospital Name]</strong>{" "}
              include:
            </Text>
            <UnorderedList sx={{ mt: "10px" }}>
              <ListItem>
                <strong>[Hospital Name 1], [Specialty]</strong>
              </ListItem>
              <ListItem>
                <strong>[Hospital Name 2], [Specialty]</strong>
              </ListItem>
              <ListItem>
                <strong>[Hospital Name 3], [Specialty]</strong>
              </ListItem>
            </UnorderedList>
          </Box>

          <Box mt="20px">
            <Text sx={styles.text}>
              To determine the award winners, Avant-garde Health examined both
              the quantity and quality of research by surgeons and hospitals.
              The study methodology can be reviewed{" "}
              <Link
                color="#3399FF"
                as={GatsbyLink}
                to={"/" + ALL_STAR_PAGES_URL.METHODOLOGY}
              >
                Here
              </Link>
              , as well as the{" "}
              <Link
                color="#3399FF"
                as={GatsbyLink}
                to={"/" + ALL_STAR_PAGES_URL.KEY_FINDINGS}
              >
                Key Findings
              </Link>{" "}
              from the study.
            </Text>
          </Box>

          <Box mt="20px">
            <Text sx={styles.text}>
              "We are proud to honor <strong>[HOSPITAL NAME]</strong> in our
              inaugural list of <strong>[SPECIALTY]</strong> Research All-Stars
              for 2024.", said Derek Haas, CEO of Avant-garde Health. "Through
              extrodinary work in healthcare research{" "}
              <strong>[HOSPITAL NAME]</strong> is helping to set new standards
              for patient care, affirming that investments in medical research
              directly translateds into better patient outcomes"
            </Text>
            <Text sx={{ mt: "20px" }}></Text>
            <Text>
              This is the inaugural launch of the Healthcare Research All-Stars
              awards. The full study results can be reviewed{" "}
              <Link
                color="#3399FF"
                as={GatsbyLink}
                to={"/" + ALL_STAR_PAGES_URL.HOME}
              >
                Here
              </Link>{" "}
              .
            </Text>
          </Box>

          <Box sx={{ mt: "20px" }}>
            <Text sx={{ fontWeight: "bold" }}>About [HOSPTAL NAME]</Text>
            <Text sx={{ fontWeight: "bold" }}>[TBD]</Text>
          </Box>

          <Box sx={{ mt: "20px" }}>
            <strong>About Avant-garde Health</strong>
            <Text sx={styles.text}>
              Across the perioperative care continuum, Avant-garde Health helps
              healthcare providers reduce costs, improve throughput and
              outcomes, and optimize coding and billing. With Avant-garde,
              hospitals, surgery centers, and physicians finally have the level
              of detail needed to see opportunities for improvement they can’t
              see now. Unique benchmarking across physicians, departments and
              peer hospitals enables providers to identify opportunities with
              the most potential for financial, operational, and quality
              improvements. To learn more, please visit{" "}
              <Link sx={styles.link} isExternal href={AGH_NETWORK.WEBSITE}>
                www.avantgardehealth.com
              </Link>
              .
            </Text>
          </Box>

          <Box mt="20px">
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#2D3748",
                mb: "10px",
              }}
            >
              Media Contacts
            </Text>
            <Text sx={styles.text}>HOSPITAL NAME</Text>
            <Text sx={styles.text}>TITLE</Text>
            <Text sx={styles.text}>E-MAIL</Text>
            <Text sx={styles.text}>PHONE</Text>
          </Box>
          <Box mt="20px">
            <Text sx={styles.text}>
              <strong>Avant-garde Health Contact</strong>
            </Text>
            <Text sx={styles.text}>Scot McLeod</Text>
            <Text sx={styles.text}>Vice President, Marketing</Text>
            <Text sx={styles.text}>404.216.4440</Text>
            <Text sx={styles.text}>
              <Link sx={styles.link} href="mailto:scot@avantgardehealth.com">
                scot@avantgardehealth.com
              </Link>
            </Text>
          </Box>
        </Box>
        <Text sx={styles.text} mt="40px" maxW="590px">
          If you have any questions or difficulty with the press release
          template, please contact us at{" "}
          <Link sx={styles.link} href={ALL_STAR_MAIL_LINK}>
            Research.All-Stars@avantgardehealth.com
          </Link>
          .
        </Text>
      </Box>
    </Flex>
  )
}

export default SectionBody
